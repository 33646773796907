type IconProps = {
  size: number;
};

export const CheckIcon = ({ size }: IconProps) => {
  return (
    <div
      style={{
        minWidth: size,
        minHeight: size,
        width: size,
        height: size,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMidYMid meet"
        version="1.0"
        viewBox="0.0 -0.0 170.0 175.9"
        zoomAndPan="magnify"
        // original_string_length="1464"
        // className="fill: rgb(255, 77, 1);"
        style={{
          fill: '#FF4D00',
        }}
      >
        <g id="__id673_so0hqn3qck">
          <path
            d="m432.17 211.89c-45.767 0-83.03 37.263-83.03 83.03-1e-5 45.767 37.263 83.03 83.03 83.03 45.767 1e-5 83.03-37.263 83.03-83.03 0-19.786-6.6953-37.48-18.065-51.976l-7.8178 9.2659c9.9118 12.204 13.791 25.754 13.791 42.71 0 39.221-31.718 70.92-70.939 70.92s-70.939-31.699-70.939-70.92 31.718-70.939 70.939-70.939c12.428 0 21.724 1.7866 31.872 7.3911l7.4275-9.7539c-11.882-6.5621-24.797-9.7286-39.299-9.7286z"
            fillRule="evenodd"
            transform="translate(-349.14 -202.1)"
            // className="fill: inherit;"
            style={{
              fill: 'inherit',
            }}
          />
          <path
            d="m401.44 284c3.3481 9e-5 5.8807 2.7473 7.5977 8.2416 3.4339 10.302 5.8807 15.453 7.3402 15.453 1.116 5e-5 2.275-0.85844 3.477-2.5755 24.124-38.632 46.445-69.882 66.963-93.748 5.3226-6.181 13.779-9.2716 25.369-9.2718 2.7471 1.7e-4 4.5928 0.25771 5.5373 0.77264 0.94418 0.51527 1.4164 1.1592 1.4165 1.9316-1.7e-4 1.2021-1.4167 3.563-4.2496 7.0826-33.138 39.835-63.873 81.901-92.203 126.2-1.9746 3.0906-6.0096 4.636-12.105 4.6359-6.1812 3e-5 -9.8299-0.25753-10.946-0.77267-2.919-1.2877-6.353-7.8552-10.302-19.703-4.4642-13.135-6.6964-21.377-6.6963-24.725-5e-5 -3.6056 3.0047-7.0826 9.0143-10.431 3.6915-2.0603 6.9538-3.0905 9.7869-3.0906"
            transform="translate(-349.14 -202.1)"
            // className="fill: inherit;"
            style={{
              fill: 'inherit',
            }}
          />
        </g>
      </svg>
    </div>
  );
};

export const Icon7 = ({ size }: IconProps) => {
  return (
    <div
      style={{
        width: size,
        height: size,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        data-name="Layer 2"
        preserveAspectRatio="xMidYMid meet"
        version="1.0"
        viewBox="3.0 4.7 58.0 55.0"
        zoomAndPan="magnify"
        style={{
          fill: '#FF4D00',
        }}
      >
        <g id="__id726_s0ku53iprk">
          <path
            d="M3.48,23.69,21.63,34.41l-.47,8.36a1,1,0,0,0,.94,1l7.48.17L40.73,58a1,1,0,0,0,.76.37l.16,0a1,1,0,0,0,.75-.63L60.94,5.93a.7.7,0,0,0,0-.14.5.5,0,0,0,0-.12,1,1,0,0,0,0-.34h0A.83.83,0,0,0,60.79,5l-.09-.1-.1-.1-.06,0-.13-.06a.76.76,0,0,0-.2-.06l-.13,0a.67.67,0,0,0-.28,0h-.08l-56,17.24a1,1,0,0,0-.67.81A1,1,0,0,0,3.48,23.69ZM23.14,41.88l.32-5.61L28,42Zm18,13.52L24.31,34.24,58,8.37Zm14-47.29L22.87,32.91,6.34,23.14Z"
            style={{
              fill: 'inherit',
            }}
          />
        </g>
        <g id="__id727_s0ku53iprk">
          <path
            d="M12.7,49.71l.21,0a1,1,0,0,0,.94-.75,22.39,22.39,0,0,1,4.25-8.82A1,1,0,0,0,18,38.8a1,1,0,0,0-1.36.15A24.19,24.19,0,0,0,12,48.55,1,1,0,0,0,12.7,49.71Z"
            style={{
              fill: 'inherit',
            }}
          />
        </g>
        <g id="__id728_s0ku53iprk">
          <path
            d="M11.36,53.36v5a1,1,0,1,0,1.93,0v-5a1,1,0,0,0-1.93,0Z"
            style={{
              fill: 'inherit',
            }}
          />
        </g>
      </svg>
    </div>
  );
};

export const Icon6 = ({ size }: IconProps) => {
  return (
    <div
      style={{
        width: size,
        height: size,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        viewBox="0 0 64 64"
        style={{
          fill: '#FF4D00',
        }}
      >
        <path
          d="M51.83 8H12.17a10 10 0 0 0-10 10v28a10 10 0 0 0 10 10h39.66a10 10 0 0 0 10-10V18a10 10 0 0 0-10-10Zm-9.12 26.64-16.41 8.8a3 3 0 0 1-1.42.36 3 3 0 0 1-3-3V23.2a3 3 0 0 1 4.42-2.64l16.41 8.8a3 3 0 0 1 0 5.28Zm-16.83-9.77L39.17 32l-13.29 7.13Z"
          style={{
            fill: '#FF4D00',
          }}
        />
      </svg>
    </div>
  );
};

export const Icon5 = ({ size }: IconProps) => {
  return (
    <div
      style={{
        width: size,
        height: size,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        viewBox="0 0 24 24"
        style={{
          fill: '#FF4D00',
        }}
      >
        <path
          d="M21,11H19.93A8,8,0,0,0,13,4.07V3a1,1,0,0,0-2,0V4.07A8,8,0,0,0,4.07,11H3a1,1,0,0,0,0,2H4.07A8,8,0,0,0,11,19.93V21a1,1,0,0,0,2,0V19.93A8,8,0,0,0,19.93,13H21a1,1,0,0,0,0-2Zm-4,2h.91A6,6,0,0,1,13,17.91V17a1,1,0,0,0-2,0v.91A6,6,0,0,1,6.09,13H7a1,1,0,0,0,0-2H6.09A6,6,0,0,1,11,6.09V7a1,1,0,0,0,2,0V6.09A6,6,0,0,1,17.91,11H17a1,1,0,0,0,0,2Zm-5-2a1,1,0,1,0,1,1A1,1,0,0,0,12,11Z"
          style={{
            fill: 'inherit',
          }}
        />
      </svg>
    </div>
  );
};

export const Icon4 = ({ size }: IconProps) => {
  return (
    <div
      style={{
        width: size,
        height: size,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        viewBox="0 0 24 24"
        style={{
          fill: '#FF4D00',
        }}
      >
        <path
          d="M0 0h24v24H0V0z"
          style={{
            fill: 'none',
          }}
        />
        <path
          d="M7 24h2v-2H7v2zm5-11c1.66 0 2.99-1.34 2.99-3L15 4c0-1.66-1.34-3-3-3S9 2.34 9 4v6c0 1.66 1.34 3 3 3zm-1 11h2v-2h-2v2zm4 0h2v-2h-2v2zm3.08-14c-.42 0-.77.3-.83.71-.37 2.61-2.72 4.39-5.25 4.39s-4.88-1.77-5.25-4.39c-.06-.41-.42-.71-.83-.71-.52 0-.92.46-.85.97.46 2.96 2.96 5.3 5.93 5.75V19c0 .55.45 1 1 1s1-.45 1-1v-2.28c2.96-.44 5.47-2.79 5.93-5.75.07-.51-.33-.97-.85-.97z"
          style={{
            fill: 'inherit',
          }}
        />
      </svg>
    </div>
  );
};

export const Icon3 = ({ size }: IconProps) => {
  return (
    <div
      style={{
        width: size,
        height: size,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        viewBox="0 0 24 24"
        style={{
          fill: '#FF4D00',
        }}
      >
        <path
          d="M0 0h24v24H0V0z"
          style={{
            fill: 'none',
          }}
        />
        <path
          d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H5.17L4 17.17V4h16v12zM7 9h2v2H7zm8 0h2v2h-2zm-4 0h2v2h-2z"
          style={{
            fill: 'inherit',
          }}
        />
      </svg>
    </div>
  );
};

export const Icon2 = ({ size }: IconProps) => {
  return (
    <div
      style={{
        width: size,
        height: size,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        viewBox="0 0 32 32"
        style={{
          fill: '#FF4D00',
        }}
      >
        <path
          d="M24.48 12.12a1 1 0 0 0-1 0l-4 2.57a1 1 0 0 0-.46.84V14a3 3 0 0 0-3-3H10a3 3 0 0 0-3 3v4a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3v-1.57a1 1 0 0 0 .46.84l4 2.57A1 1 0 0 0 24 20a1 1 0 0 0 .48-.12A1 1 0 0 0 25 19v-6a1 1 0 0 0-.52-.88ZM17 18a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1Zm6-.83L21.18 16 23 14.83ZM16 2A14 14 0 0 0 2 16a13.85 13.85 0 0 0 3.73 9.48 7.19 7.19 0 0 1-2.12 2l-1.12.67A1 1 0 0 0 3 30h4a8.73 8.73 0 0 0 4-.95A14 14 0 1 0 16 2Zm0 26a12 12 0 0 1-4.69-.95h-.06a.48.48 0 0 0-.2 0h-.36l-.21.06h-.05A6.84 6.84 0 0 1 7 28h-.81a8.94 8.94 0 0 0 1.67-2.15s0-.05 0-.07l.14-.19a1.25 1.25 0 0 0 0-.19 1.37 1.37 0 0 0 0-.19 1.16 1.16 0 0 0 0-.18 1.3 1.3 0 0 0-.08-.17.61.61 0 0 0-.12-.16.21.21 0 0 0 0-.06A12 12 0 1 1 16 28Z"
          data-name="22 video call"
          style={{
            fill: 'inherit',
          }}
        />
      </svg>
    </div>
  );
};

export const Icon1 = ({ size }: IconProps) => {
  return (
    <div
      style={{
        width: size,
        height: size,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        viewBox="0 0 60 60"
        style={{
          fill: '#FF4D00',
        }}
      >
        <path
          d="M29.924 25.33c0 .56.45 1 1 1 1.14 0 2.06.93 2.06 2.06 0 .55.45 1 1 1s1-.45 1-1c0-2.24-1.82-4.06-4.06-4.06-.55 0-1 .45-1 1zm16.28 4.06c.56 0 1-.45 1-1 0-8.98-7.3-16.28-16.28-16.28-.55 0-1 .45-1 1s.45 1 1 1c7.88 0 14.28 6.41 14.28 14.28 0 .55.45 1 1 1z"
          style={{
            fill: 'inherit',
          }}
        />
        <path
          d="M39.094 28.39c0 .55.45 1 1 1s1-.45 1-1c0-5.61-4.56-10.17-10.17-10.17-.55 0-1 .45-1 1 0 .56.45 1 1 1 4.51 0 8.17 3.67 8.17 8.17zM30.924 6c-.55 0-1 .45-1 1s.45 1 1 1c11.25 0 20.4 9.15 20.4 20.39 0 .55.44 1 1 1 .55 0 1-.45 1-1 0-12.35-10.05-22.39-22.4-22.39zm9.77 32.93-1.31.71c-2.21 1.21-5.01.8-6.8-.99l-10.56-10.56c-1.79-1.79-2.2-4.59-.99-6.81l.71-1.3c.8-1.48 1.09-3.14.85-4.8a7.608 7.608 0 0 0-2.18-4.37c-1.46-1.46-3.4-2.26-5.47-2.26s-4.02.8-5.48 2.26l-.51.52c-2.7 2.7-3.01 7.35-.88 13.11 2.01 5.5 6.06 11.46 11.38 16.78C27.564 49.33 36.594 54 43.074 54c2.56 0 4.73-.73 6.27-2.27l.52-.52a7.74 7.74 0 0 0 2.27-5.48 7.855 7.855 0 0 0-2.27-5.47c-2.41-2.41-6.18-2.95-9.17-1.33z"
          style={{
            fill: 'inherit',
          }}
        />
      </svg>
    </div>
  );
};
