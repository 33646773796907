import Website from 'components/organisms/Website';
import { TeacherProfile } from 'services/apiServices/IProTubeApi/schemas/TeacherProfile.schema';

export type HomePageProps = {
  defaultTeacherProfiles: TeacherProfile[];
};

const HomePage = ({ defaultTeacherProfiles }: HomePageProps) => {
  return <Website defaultTeacherProfiles={defaultTeacherProfiles} />;
};

export default HomePage;

// export const getStaticProps: GetStaticProps = async () => {
//   const { results } = await getSearchTeacherProfiles({
//     filters: {
//       isOnline: true,
//     },
//     pagination: {
//       limit: 99999,
//       skip: 0,
//     },
//     populates: { 'populate-mainTheme': true },
//   });

//   return {
//     props: {
//       defaultTeacherProfiles: results || [],
//     } as HomePageProps,
//   };
// };
