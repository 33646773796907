import Link from 'next/link';
import Image from 'next/image';
import React from 'react';
import { AiFillInstagram, AiFillYoutube } from 'react-icons/ai';
import { FaFacebookF } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="w-full h-fit flex flex-col justify-center items-center gap-4 px-2 py-8 lg:py-10 bg-black">
      <div className="w-56 max-w-full lg:w-80 h-auto">
        <Image
          src="/branding/logo-menobel-white.svg"
          width={579}
          height={87}
          layout="responsive"
          className="object-contain"
          alt="Logo Menobel"
        />
      </div>
      <div className="w-full flex lg:hidden justify-center items-center gap-2">
        <Link href="#">
          <a target="_blank" className="cursor-pointer">
            <FaFacebookF size={25} color="#ffffff" />
          </a>
        </Link>
        <Link href="#">
          <a target="_blank" className="cursor-pointer">
            <AiFillInstagram size={25} color="#ffffff" />
          </a>
        </Link>
        <Link href="#">
          <a target="_blank" className="cursor-pointer">
            <AiFillYoutube size={25} color="#ffffff" />
          </a>
        </Link>
      </div>
      <div className="w-full hidden lg:flex justify-center items-center gap-3">
        <Link href="#">
          <a target="_blank" className="cursor-pointer">
            <FaFacebookF size={25} color="#ffffff" />
          </a>
        </Link>

        <Link href="#">
          <a target="_blank" className="cursor-pointer">
            <AiFillInstagram size={35} color="#ffffff" />
          </a>
        </Link>

        <Link href="#">
          <a target="_blank" className="cursor-pointer">
            <AiFillYoutube size={40} color="#ffffff" />
          </a>
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
