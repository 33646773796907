import Link from 'next/link';

export const CtaButton = () => {
  return (
    <Link href="/p/cadastrar-se">
      <a className="sm:min-w-max px-4 py-2 text-xl text-white font-bold bg-primaryColor rounded-tl-xl rounded-br-xl cursor-pointer">
        Criar uma Referência grátis
      </a>
    </Link>
  );
};
