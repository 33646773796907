import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Image from 'next/image';

import { useAuth } from 'contexts/Authentication';
import {
  TEACHER_TYPES,
  TeacherProfile,
  getTeacherTypeLabel,
} from 'services/apiServices/IProTubeApi/schemas/TeacherProfile.schema';
import { SearchFilterTeacherProfilesDTO } from 'services/apiServices/IProTubeApi/teacherProfiles/types';
import Footer from 'components/molecules/Website/components/Footer';
import HeaderBeforeLogin from 'components/header';
import {
  CheckIcon,
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
} from './components/icons';
import { CtaButton } from './components/CtaButton';

export type WebsiteProps = {
  defaultTeacherProfiles: TeacherProfile[];
};

const CarouselRefences = dynamic(
  () => import('components/molecules/CarouselRefences'),
  {
    ssr: false,
  },
);

const Website = ({ defaultTeacherProfiles }: WebsiteProps) => {
  const [isSectionVideoAudioEnabled, setIsSectionVideoAudioEnabled] = useState(
    false,
  );

  useEffect(() => {
    const handleAudio = () => {
      setIsSectionVideoAudioEnabled(true);
    };
    window.addEventListener('click', handleAudio);

    return () => {
      window.removeEventListener('click', handleAudio);
    };
  }, []);

  return (
    <div className="w-full h-fit flex flex-col">
      <div
        className="w-full min-h-screen h-fit flex flex-col items-center gap-8"
        style={{ minHeight: '90vh' }}
      >
        {/* 
        TODO: Implementar pesquisa de Nobers onde o usuário
        será redirecionado para uma outra tela ao confirmar
        a pesquisa 
        */}
        <HeaderBeforeLogin />
        <section className="w-full flex flex-col lg:justify-center items-center relative websiteGradient websiteHeroSectionWrapper">
          <video
            id="background-video"
            loop
            controls={false}
            muted={true}
            playsInline={true}
            autoPlay={true}
            placeholder="/images/menobel-website-bg-video-placeholder.png"
            className="w-full h-full object-cover absolute left-0 right-0 top-0 z-10 websiteBgVideo"
          >
            <source
              src="/videos/menobel-website-bg-video.mp4"
              type="video/mp4"
            />
          </video>

          <div className="w-full max-w-7xl h-full flex flex-col lg:flex-row gap-8 relative z-20 pt-24 px-8 pb-8">
            <div className="w-full h-full flex flex-col gap-4 text-white">
              <h1 className="text-2xl lg:text-4xl font-bold">
                Ganhe com suas respostas! Sua experiência de vida ao serviço da
                humanidade!
              </h1>

              <p className="text-lg lg:text-2xl font-bold">
                Conversas mudam o mundo!
              </p>

              <p className="text-lg lg:text-2xl font-bold">
                De aqueles que sabem para aqueles que querem saber.
              </p>

              <ul className="w-full px-6 flex flex-col gap-4 list-none text-sm lg:text-base">
                <li className="w-full flex items-center gap-2">
                  <CheckIcon size={20} />
                  <span className="font-medium">
                    Converse com outros e ganhe
                  </span>
                </li>
                <li className="w-full flex items-center gap-2">
                  <CheckIcon size={20} />
                  <span className="font-medium">
                    Sem necessidade de criar conteúdo algum
                  </span>
                </li>
                <li className="w-full flex items-center gap-2">
                  <CheckIcon size={20} />
                  <span className="font-medium">
                    Monetize sua experiência de vida
                  </span>
                </li>
                <li className="w-full flex items-center gap-2">
                  <CheckIcon size={20} />
                  <span className="font-medium">
                    Conversas e videconferências gravadas (conteùdo orgánico)
                  </span>
                </li>
              </ul>

              <div className="mx-auto flex">
                <CtaButton />
              </div>
            </div>

            <div className="w-full flex justify-start">
              <p className="max-w-xs sm:max-w-md lg:max-w-none lg:mt-auto text-xl lg:text-2xl text-black font-medium bg-boxGreen mr-auto lg:mr-0 px-3 py-2">
                Um super chat onde pode colocar preço a qualquer ação realizada,
                ponha preço às suas respostas. Empreendedores e influencers
                podem também por preço às perguntas que queiram fazer e garantir
                que sejam vistas.
              </p>
            </div>
          </div>
        </section>
      </div>

      <main className="w-full flex flex-col items-center gap-8 p-4 md:p-8 websiteGradient">
        <div className="w-full flex flex-col items-center gap-8 text-white">
          <h2 className="text-4xl font-semibold">Conheça os formatos</h2>

          <p className="uppercase text-center font-medium">
            DÊ DICAS, OPINIOES, CONSELHOS E INSPIRE PESSOAS DE 7 MANEIRAS
            DIFERENTES
          </p>

          <ul className="w-full max-w-6xl px-14 sm:px-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center gap-4 list-none text-sm">
            <li className="w-full max-w-sm justify-self-center flex flex-col items-center gap-4 border-4 border-primaryColor rounded-2xl px-2 py-4">
              <div className="w-full flex justify-center items-center gap-2">
                <Icon1 size={40} />
                <h3 className="flex justify-center items-center font-medium text-primaryColor text-base">
                  CHAMADA DE VOZ
                </h3>
              </div>

              <p className="uppercase font-medium text-center">
                ATENDA CHAMADAS CRONOMETRADAS DEFINIDAS COM O PREÇO DO SEU
                MINUTO
              </p>
            </li>

            <li className="w-full max-w-sm justify-self-center flex flex-col items-center gap-4 border-4 border-primaryColor rounded-2xl px-2 py-4">
              <div className="w-full flex justify-center items-center gap-2">
                <Icon2 size={40} />
                <h3 className="flex justify-center items-center font-medium text-primaryColor text-base">
                  VIDEOCONFERÊNCIA
                </h3>
              </div>

              <p className="uppercase font-medium text-center">
                ATENDA VIDEOCONFERÊNCIAS CRONOMETRADAS DEFINIDAS COM O PREÇO DO
                SEU MINUTO
              </p>
            </li>

            <li className="w-full max-w-sm justify-self-center flex flex-col items-center gap-4 border-4 border-primaryColor rounded-2xl px-2 py-4">
              <div className="w-full flex justify-center items-center gap-2">
                <Icon3 size={40} />
                <h3 className="flex justify-center items-center font-medium text-primaryColor text-base">
                  MENSAGENS DE TEXTO
                </h3>
              </div>

              <p className="uppercase font-medium text-center">
                PONHA UM PREÇO À SUA RESPOSTA E VENDA MENSAGENS.
              </p>
            </li>

            <li className="w-full max-w-sm justify-self-center flex flex-col items-center gap-4 border-4 border-primaryColor rounded-2xl px-2 py-4">
              <div className="w-full flex justify-center items-center gap-2">
                <Icon4 size={40} />
                <h3 className="flex justify-center items-center font-medium text-primaryColor text-base">
                  MENSAGEM DE VOZ
                </h3>
              </div>

              <p className="uppercase font-medium text-center">
                PONHA UM PREÇO A CADA MENSAGEM DE VOZ QUE ENVIA E GANHE
              </p>
            </li>

            <li className="w-full max-w-sm justify-self-center flex flex-col items-center gap-4 border-4 border-primaryColor rounded-2xl px-2 py-4">
              <div className="w-full flex justify-center items-center gap-2">
                <Icon5 size={40} />
                <h3 className="flex justify-center items-center font-medium text-primaryColor text-base">
                  INFOPRODUTOS
                </h3>
              </div>

              <p className="uppercase font-medium text-center">
                ENVIE DOCUMENTOS PARA AS PESSOAS COM O SEU PREÇO ESTIPULADO E
                AUMENTE AINDA MAIS SUA RENDA
              </p>
            </li>

            <li className="w-full max-w-sm justify-self-center flex flex-col items-center gap-4 border-4 border-primaryColor rounded-2xl px-2 py-4">
              <div className="w-full flex justify-center items-center gap-2">
                <Icon6 size={40} />
                <h3 className="flex justify-center items-center font-medium text-primaryColor text-base">
                  VÍDEOS
                </h3>
              </div>

              <p className="uppercase font-medium text-center">
                GRAVE VÍDEOS, ESTIPULE O SEU PREÇO, ENVIE E RECEBA NA HORA.
              </p>
            </li>

            <li className="w-full max-w-sm justify-self-center lg:col-start-2 lg:col-end-3 flex flex-col items-center gap-4 border-4 border-primaryColor rounded-2xl px-2 py-4">
              <div className="w-full flex justify-center items-center gap-2">
                <Icon7 size={40} />
                <h3 className="flex justify-center items-center font-medium text-primaryColor text-base">
                  MD INTELIGENTES
                </h3>
              </div>

              <p className="uppercase font-medium text-center">
                RESPONDA A SEGUIDORES ESPECIAIS, PONHA UM PREÇO ÂS PERGUNTAS QUE
                LHE QUEIRAM FAZER E GARANTA SUA LEITURA (SÓ PARA EMPREENDEDORES
                E INFLUENCERS)
              </p>
            </li>
          </ul>

          <CtaButton />
        </div>

        <div className="w-full max-w-6xl flex flex-col items-center gap-8 text-white">
          <h2 className="text-3xl sm:text-4xl font-semibold">
            Oriente. Explique. Inspire.
          </h2>

          <div className="w-full customGridCols1">
            <video
              id="cta-video"
              controls={true}
              className="w-full h-auto self-center"
              muted={!isSectionVideoAudioEnabled}
              playsInline={true}
              autoPlay={false}
              poster="/images/menobel-cta-video-thumbnail.jpg"
            >
              <source
                src="/videos/menobel-website-section-video.mp4"
                type="video/mp4"
              />
            </video>

            <div
              className="none md:block h-full bg-primaryColor"
              style={{ width: '1px' }}
            />

            <div className="w-full flex flex-col gap-8 p-4 lg:p-0">
              <h3 className="text-4xl font-bold text-left">
                4 Categorias, qual Nober você é?
              </h3>

              <ul className="w-full flex flex-col gap-2 text-lg pl-8 list-disc">
                <li>
                  <span className="font-bold">Entusiastas Apaixonados</span>{' '}
                  (Hobbies e experiencias de vida interessantes)
                </li>
                <li>
                  <span className="font-bold">Profissionais</span>{' '}
                  (Conhecimentos formais)
                </li>
                <li>
                  <span className="font-bold">Empreendedores</span>{' '}
                  (Desenvolvedores de soluçoes e investidores)
                </li>
                <li>
                  <span className="font-bold">Influencers</span> (Artistas,
                  atletas, estrelas da cultura pop, expertos de setor, lideres
                  de opinião, blogueiros e criadores de conteúdos)
                </li>
              </ul>
            </div>
          </div>

          <CtaButton />
        </div>

        <div className="w-full max-w-6xl flex flex-col items-center gap-8 text-white">
          <h2 className="text-3xl sm:text-4xl font-semibold">
            Porque para você?
          </h2>

          <div className="w-full flex flex-col lg:flex-row justify-center items-center gap-8">
            <div className="w-full max-w-md lg:max-w-none flex justify-center items-center">
              <Image
                src="/images/menobel-nober-registration-illustration.webp"
                alt="Image"
                className="object-contain"
                width={797}
                height={861}
              />
            </div>

            <div className="w-full flex flex-col gap-8 p-4 lg:p-0">
              <h3 className="text-4xl font-bold text-left">
                4 Categorias, qual Nober você é?
              </h3>

              <ul className="w-full flex flex-col gap-2 text-lg pl-8 list-disc">
                <li>
                  <span className="font-bold">Entusiastas Apaixonados</span>{' '}
                  Aproveitamento orgânico de sua experiência de vida e hobbies.
                  Todo o mundo sabe responder uma mensagem ou conversar com
                  alguém para partilhar suas experiências (facilidade total).
                  Ganhar um dinheiro extra.
                </li>
                <li>
                  <span className="font-bold">Profissionais</span> Ganhar um
                  dinheiro extra, iniciar conversas com potenciais clientes.
                </li>
                <li>
                  <span className="font-bold">Empreendedores</span> Ouvir
                  propostas de potenciais projetos. Ganhar um dinheiro extra.
                  Ouvir aqueles que realmente querem seguir seus passos, a labor
                  social. Criar conteúdos orgânicos gratuitos.
                </li>
                <li>
                  <span className="font-bold">Influencers</span> Chegar perto de
                  seus seguidores mais especiais que estão dispostos a investir
                  para obter esse momento. Gerar conteúdos orgânicos gratuitos.
                  Ganhar mais um dinheiro extra. Labor social.
                </li>
              </ul>
            </div>
          </div>

          <CtaButton />
        </div>

        <div className="w-full max-w-6xl flex flex-col items-center gap-8 text-white">
          <div className="w-full flex flex-col gap-6">
            <div
              className="flex flex-col gap-2 text-xl font-medium"
              style={{ width: 'fit-content' }}
            >
              Como começar?
              <div className="w-full" style={{ paddingRight: '20%' }}>
                <div
                  className="w-full bg-primaryColor"
                  style={{ height: '0.125rem' }}
                />
              </div>
            </div>

            <h2 className="w-full text-4xl text-center lg:text-left font-semibold">
              Comece o seu novo hobbie remunerado em poucos passos
            </h2>
          </div>

          <div className="w-full flex flex-col lg:flex-row items-center gap-8">
            <ul className="w-full flex flex-col gap-8">
              <li className="w-full flex flex-col gap-2">
                <h3 className="text-4xl font-bold">
                  <span>01</span>
                  <span className="text-primaryColor">.</span>{' '}
                  <span className="text-smtitleColor">Crie sua conta</span>
                </h3>

                <p className="text-lg font-medium">
                  A inscrição é simples e completamente gratuita.
                </p>
              </li>

              <li className="w-full flex flex-col gap-2">
                <h3 className="text-4xl font-bold">
                  <span>02</span>
                  <span className="text-primaryColor">.</span>{' '}
                  <span className="text-smtitleColor">Crie sua Referência</span>
                </h3>

                <p className="text-lg font-medium">
                  Em poucos passos e de forma rápida iremos guiá-lo para que
                  crie de forma simples a sua Referência Menobel. Durante o
                  processo saiba que o nosso chat está disponível a toda hora
                  para resolver qualquer dúvida.
                </p>
              </li>

              <li className="w-full flex flex-col gap-2">
                <h3 className="text-4xl font-bold">
                  <span>03</span>
                  <span className="text-primaryColor">.</span>{' '}
                  <span className="text-smtitleColor">Converse e ganhe</span>
                </h3>

                <p className="text-lg font-medium">
                  Uma ferramenta total para que nossa experiência de vida e
                  autoridade possa passar adiante e servir âs relaçoes humanas
                  de forma Win-Win.
                </p>
              </li>
            </ul>

            <div className="w-full max-w-md lg:max-w-none">
              <Image
                src="/images/menobel-website-section-video.webp"
                alt="Image"
                width={800}
                height={533}
              />
            </div>
          </div>

          <p className="text-3xl text-primaryColor text-center font-medium uppercase">
            VENHA VIVER MOMENTOS ÚNICOS E MARCAR A VIDA DAS PESSOAS
          </p>

          <CtaButton />
        </div>
      </main>

      <div className="w-full flex flex-col lg:flex-row">
        <div className="w-full bg-boxGray p-4 lg:p-8 text-4xl font-medium">
          Sem investimento nenhum, sem parcelas. Só paga quando vender.
        </div>

        <div className="w-full lg:w-2/5 bg-boxGreen p-4 lg:p-8 flex flex-col gap-4">
          <div className="w-full text-4xl font-bold">
            Entre 0% e 20% de comissão
          </div>

          <div className="w-full flex lg:flex-col lg:gap-4 justify-between items-center flex-wrap lg:flex-nowrap">
            <p className="lg:w-full text-left">*Detalhes nas FAQs</p>

            <CtaButton />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Website;

// export const getStaticProps: GetStaticProps = async () => {
//   const { results } = await getSearchTeacherProfiles({
//     filters: {
//       isOnline: true,
//     },
//     pagination: {
//       limit: 99999,
//       skip: 0,
//     },
//     populates: { 'populate-mainTheme': true },
//   });

//   return {
//     props: {
//       defaultTeacherProfiles: results || [],
//     } as WebsiteProps,
//   };
// };
