import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import Link from 'next/link';
import Image from 'next/image';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import { showMessageStatus } from 'utils/SwalUtils';

const HeaderBeforeLogin = () => {
  const { handleSubmit } = useForm();
  const inputRef = useRef(null);
  const handleOnSubmit = handleSubmit(() => {
    showMessageStatus({
      imageSrc: '/images/blocos.png',
      titleContent: 'Comunidade em construção.',
      textButton: 'Ok',
      textContent:
        'A equipa Menobel agradece seu entusiasmo! Muito em breve teremos uma comunidade pronta para Guiar, Orientar e Inspirar você na sua caminhada de vida.',
      customClass: 'font-poppins',
      onIsAccepted: () => {
        //
      },
    });
  });

  return (
    <header className="w-full h-fit flex flex-col gap-2 font-montserrat px-2 py-2 md:px-8 absolute z-30">
      <div className="w-full max-w-7xl flex justify-between items-center gap-2 md:gap-4 m-auto">
        <div className="w-80 md:w-96 max-w-sm">
          <Image
            src="/branding/logo-orange-menobel.png"
            width={211.5}
            height={46.5}
            layout="responsive"
            className="object-contain"
            alt="Logo Menobel"
          />
        </div>

        <form
          className="w-full flex justify-center items-center md:gap-2 text-sm md:text-lg"
          onSubmit={event => {
            if (inputRef.current) {
              event.preventDefault();
              inputRef.current.blur();
            } else {
              handleOnSubmit(event);
            }
          }}
        >
          <select className="h-7 w-20 md:h-8 max-w-md bg-white rounded-full px-2">
            <option value="">Tipo</option>
            <option value="">Entusiasta Apaixonado</option>
            <option value="">Profissional</option>
            <option value="">Empreendedor</option>
            <option value="">Influencer</option>
          </select>

          <div className="w-full max-w-lg flex justify-center items-center">
            <div className="h-7 md:h-8 rounded-l-full px-1 md:px-2 bg-white flex justify-center items-center">
              <HiMagnifyingGlass size={20} />
            </div>
            <input
              ref={inputRef}
              type="text"
              className="h-7 md:h-8 w-full bg-white rounded-r-full pr-2"
              placeholder="Procure qualquer tema ou pessoa"
              onBlur={handleOnSubmit}
            />
          </div>
        </form>

        <div className="flex-auto w-max flex justify-end items-center gap-2 md:gap-4 text-sm md:text-lg">
          <Link href="/login">
            <span className="font-bold text-white cursor-pointer">Login</span>
          </Link>
          <Link href="/p/cadastrar-se">
            <a className="w-max bg-primaryColor text-white rounded-lg py-1 px-1 md:py-3 md:px-3 pt-1 pb-1">
              Cadastre-se
            </a>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default HeaderBeforeLogin;
